<template>
  <eagle-form
    :form-key="formKey"
  ></eagle-form>
</template>

<script lang="babel" type="text/babel">
import formMixin from '@/components/form/mixins/formMixin'
import formConfig from '@/components/tree/nodeAction/treeNodeEditPopup/treeNodeEditForm'
export default {
  mixins: [formMixin],
  props: {
    preventClose: Boolean,
    popupConfig: Object,
  },
  data: (vm) => ({
    bindRoute: false,
    formKey: vm.popupConfig.formKey,
  }),
  methods: {
    forceSetMode() {
      return 'update'
    },
    readApi() {
      return this.popupConfig.node
    },
    async updateApi(id, data) {
      return await this.treeApi.updateApi(id, data)
    },
    getFormConfig() {
      return formConfig
    },
    async formSaveFinished(result) {
      await this.treeActions.refreshTree()
      if(result) {
        this.$emit('close')
      }
    },
  },
  computed: {
    target() {
      return this.popupConfig.node.id
    },
    treeKey() {
      return this.popupConfig.treeKey
    },
    treeApi() {
      return this.$store.getters[`tree/${this.treeKey}/api`]
    },
    treeActions() {
      return this.$store.getters[`tree/${this.treeKey}/actions`]
    },
    finished() {
      if(!this.hasInit) return false
      return this.$store.getters[`form/${this.formKey}/finished`]
    },
    hasChanged() {
      if(!this.hasInit) return false
      return this.$store.getters[`form/${this.formKey}/hasChanged`]
    },
    popupData() {
      return {
        finished: this.finished,
        hasChanged: this.hasChanged,
      }
    },
  },
  watch: {
    hasChanged: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('update:preventClose', this.hasChanged)
      },
    },
    popupData: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('updateData', this.popupData)
      },
    }
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
class formConfig {
  protected vm?: Vue
  protected formKey?: string

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      minHeight: '160px',
      disabledBottomNavigation: true,
      blocks: [
        {
          data: {
            name: {
              required: true,
              label: 'data.tree_node.name',
              type: 'text',
              focus: true,
              grid: { xl: 12, lg: 12, md: 12},
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
          },
        },
      ],
    }
  }
}

export default new formConfig()